import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from '@blendin/sdk-react';
import { UI_ROUTES, API_ROUTES } from '../helpers/routes';
import { Spinner } from '../components/Spinner';
import { UserSettings } from '../types/user';
import { SubscriptionTier } from '../types/subscription_tier';
import { APIClient } from '../services/APIService';
import { numberToCurrency } from '../helpers/strings';
import { useModal } from '../hooks/useModal';
import { ModalType } from '../types/modal';

const SubscriptionTierFeatures: React.FC<{subscriptionTier: SubscriptionTier}> = ({subscriptionTier}) => {
  const { t } = useTranslation();

  if (subscriptionTier.name === 'free') {
    return (
      <div className='subscription-tier-features'>
        <p className='max-locales'>
          {t('Maximum of {{maxLocales}} locales per project', {
            maxLocales: `2`
          })}
        </p>
        <p className='locale-token-limit'>
          {t('Free up to {{tokenAmount}} locale tokens per month', {
            tokenAmount: subscriptionTier.locale_token_limit.toLocaleString('en-US')
          })}
        </p>
        <p className='user-seats'>
          {t('Maximum of {{usersPerProject}} user per project', {
            usersPerProject: `1`
          })}
        </p>
      </div>
    )
  }

  if (subscriptionTier.name === 'pro') {
    return (
      <div className='subscription-tier-features'>
        <p className='max-locales'>
          {t('+ Unlimited locales per project')}
        </p>
        {/* <p className='locale-token-limit'>
          {t('Max {{tokenAmount}} locale tokens', {
            tokenAmount: subscriptionTier.locale_token_limit.toLocaleString('en-US')
          })}
        </p> */}
        <p className='user-seats'>
          {t('+ Unlimited users per project')}
        </p>
      </div>
    )
  }

  if (subscriptionTier.name === 'elite') {
    return (
      <div className='subscription-tier-features'>
        <p className='manual-qa'>
          {t('+ Human QA of all translation strings')}
        </p>
        <p className='max-locales'>
          {t('Unlimited locales per project')}
        </p>
        {/* <p className='locale-token-limit'>
          {t('Max {{tokenAmount}} locale tokens', {
            tokenAmount: subscriptionTier.locale_token_limit.toLocaleString('en-US')
          })}
        </p> */}
        <p className='user-seats'>
          {t('Unlimited users per project')}
        </p>
      </div>
    )
  }

  // if (subscriptionTier.name === 'unlimited') {
  //   return (
  //     <div className='subscription-tier-features'>
  //       <p className='locale-token-limit'>
  //         {t('max {{tokenAmount}} locale tokens', {
  //           tokenAmount: subscriptionTier.locale_token_limit.toLocaleString('en-US')
  //         })}
  //       </p>
  //     </div>
  //   )
  // }

  return (<></>);
}

export const PricingScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setActiveModal } = useModal();
  const [subscriptionTiers, setSubscriptionTiers] = useState<SubscriptionTier[]>([]);
  const [user, setUser] = useState<UserSettings | undefined>(undefined);

  useEffect(() => {
    (async () => {
      const subscriptionTiersResponse = await APIClient.get(API_ROUTES.SubscriptionTiersIndex);

      if (subscriptionTiersResponse.status === 200) {
        setSubscriptionTiers(subscriptionTiersResponse.data.subscription_tiers);
      }
    })();
  }, [])

  useEffect(() => {
    (async () => {
      const userResponse = await APIClient.authenticatedGet(API_ROUTES.UserSettings);

      if (userResponse.status === 200) {
        setUser(userResponse.data.user as UserSettings);
      }
    })();
  }, []);

  const handleSelectPlan = (subscriptionTier: SubscriptionTier) => {
    if (!user) {
      navigate(UI_ROUTES.Login);
    } else if (user.has_active_card) {
      setActiveModal(ModalType.ChangeSubscriptionTier, {
        subscriptionTier,
        userHasActiveCard: true
      });
    } else {
      setActiveModal(ModalType.ChangeSubscriptionTier, {
        subscriptionTier,
        userHasActiveCard: false
      });
    }
  }

  return (
    <div className='pricing-screen'>
      <div className='content-container-wide'>
        {subscriptionTiers.length > 0 ? (
          <>
            <h2 className='pricing-title'>{t('Pricing')}</h2>
            <div className='subscription-tiers'>
              {subscriptionTiers.map((subscriptionTier, index) => (
                <div
                  key={index}
                  className='subscription-tier'>
                  <h3 className='subscription-tier-name'>{subscriptionTier.name}</h3>
                  <p className='subscription-tier-base-price'>
                    {t('{{basePrice}} / month', {
                      basePrice: numberToCurrency(subscriptionTier.base_price)
                    })}
                  </p>
                  <div
                    className='action-button primary select-plan'
                    onClick={() => handleSelectPlan(subscriptionTier)}>
                    <p className='action-button-text'>{t('Select this plan')}</p>
                  </div>
                  <hr />
                  <SubscriptionTierFeatures subscriptionTier={subscriptionTier} />
                </div>
              ))}
            </div>
          </>
        ) : (
          <Spinner spinnerType='chase' />
        )}
      </div>
    </div>
  )
}
