import React, { useEffect, useState } from 'react';
import { useTranslation } from '@blendin/sdk-react';
import { User } from '../types/user';
import { ProjectIndex } from '../types/project';
import { APIClient } from '../services/APIService';
import { useNavigate } from 'react-router-dom';
import { UI_ROUTES, API_ROUTES } from '../helpers/routes';
import { DataTable } from '../components/DataTable';
import { Spinner } from '../components/Spinner';
import moment from 'moment';

export const UserDashboardScreen: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [projects, setProjects] = useState<ProjectIndex[]>([]);

  useEffect(() => {
    (async () => {

      const userResponse = await APIClient.authenticatedGet(API_ROUTES.UserDashboard);

      if (userResponse.status === 200) {
        setUser(userResponse.data.user as User);
        setProjects(userResponse.data.user.projects as ProjectIndex[])
      } else if (userResponse.status === 401) {
        navigate(UI_ROUTES.Login);
      }

    })();
  }, []);

  return (
    <div className='Screen user-dashboard-screen'>
      <div className='content-container'>
        {user ? (
          <>
            <h2 className='user-email'>{user.email}</h2>
            {projects.length > 0 ? (
              <>
                <DataTable
                  className='projects-table'
                  headerRow={[t('Project Name'), t('Privacy Mode'), t('Locale tokens'), t('Role'), t('Updated')]}
                  dataRows={
                    projects.map((project, index) => ({
                      onClickRow: () => navigate(UI_ROUTES.ProjectDashboard.replace(':uuid', project.uuid)),
                      items: [
                        project.name,
                        project.privacy_mode,
                        project.locale_token_count,
                        project.current_user_role,
                        moment.unix(project.updated_at).fromNow()
                      ]
                    }))
                  }
                />
                <div className='action-buttons'>
                  <div className='flex-spacer' />
                  <div
                    className='action-button primary create-project'
                    onClick={() => navigate(UI_ROUTES.ProjectCreate)}>
                    <p>{t({text: 'Create Project', context: 'command'})}</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p className='null-state-text'>No projects found. Create your first project by clicking the button below.</p>
                <div className='action-buttons'>
                  <div className='action-button primary create-project'
                  onClick={() => navigate(UI_ROUTES.ProjectCreate)}>
                  <p>{t({text: 'Create Project', context: 'command'})}</p>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <Spinner spinnerType='Chase' />
        )}
      </div>
    </div>
  )
}
