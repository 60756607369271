export enum UI_ROUTES {
  Documentation = '/documentation',
  Error = '/error',
  Home = '/',
  Login = '/login',
  LogoutCallback = '/logout',
  OAuthGoogleLoginCallback = '/oauth/google/login-callback',
  OAuthGoogleSignupCallback = '/oauth/google/signup-callback',
  Pricing = '/pricing',
  ProjectCreate = '/project/create',
  ProjectDashboard = '/project/:uuid',
  ProjectLocaleShow = '/project/:uuid/locale/:iso',
  ProjectTranslationJobCreate = '/project/:uuid/translation-job/create',
  ProjectTranslationJobShow = '/project/:project_uuid/translation-job/:translation_job_uuid',
  Signup = '/signup',
  TranslatorConfirmTranslations = '/translator/locale/:iso',
  TranslatorDashboard = '/translator/dashboard',
  UserDashboard = '/dashboard',
  UserSettings = '/settings',
  UserSignupCallback = '/user-signup-callback'
}

export enum API_ROUTES {
  LocalesIndex = 'locales',
  OAuthGoogleLoginCallback = 'oauth/google/login-callback',
  OAuthGoogleSignupCallback = 'oauth/google/signup-callback',
  ProjectCreate = 'projects/create',
  ProjectDashboard = 'projects/:uuid',
  ProjectLocaleShow = 'projects/:uuid/locales/:iso',
  ProjectLocaleTranslationJobCreate = 'projects/:uuid/locales/:iso/create-translation-job',
  ProjectGithubRepositoryConnectionCreate = 'project-github-repository-connections',
  ProjectUploadFile = 'projects/:uuid/upload-file',
  SourceFileTranslationJobCreate = 'projects/:project_uuid/source_files/:source_file_uuid/source_file_version/:version/create-translation-job',
  StripeAddPaymentMethod = 'stripe/add-payment-method',
  SubscriptionTiersIndex = 'subscription-tiers',
  TranslationJobsCreate = 'translation-jobs/create',
  TranslationStringConfirmTranslation = 'translation-strings/confirm-translation',
  TranslationStringEditTranslation = 'translation-strings/edit-translation',
  TranslatorConfirmTranslations = 'users/translator/locales/:iso',
  TranslatorDashboard = 'users/translator/dashboard',
  UserCreate = 'users/create',
  UserCreateApiToken = 'users/api-tokens/create',
  UserDashboard = 'users/dashboard',
  UserGithubRepositories = 'github-integrations/repositories',
  UserLogin = 'users/login',
  UserSettings = 'users/settings',
  UserSubscriptionTierUpdate = 'users/update-subscription-tier'
}
